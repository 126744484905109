import gql from 'graphql-tag';

export default gql`
query getAllFoldersProjectsAndPagesByFolderId($folderId: Int!) {
  folders(where: {parent_folder_id: {_eq: $folderId}}) {
    name
    folder_id
    slug
    lookup_entries {
      slug_path
    }
    order_index
    group {
      name
      slug
      domain
    }
  }
  projects(where: {parent_folder_id: {_eq: $folderId}}) {
    name
    project_id
    slug
    thumbnails
    order_index
    lookup_entries {
      slug_path
    }
    plugins {
      handle
      enabled
    }
    group {
      name
      slug
      domain
    }
  }
  pages(where: {parent_folder_id: {_eq: $folderId}}) {
    name
    page_id
    slug
    thumbnails
    order_index
    lookup_entries {
      slug_path
    }
    group {
      name
      slug
      domain
    }
  }
}
`;
