import gql from 'graphql-tag';

export default gql`
query getAllPagesByProjectId($projectId: Int!) {
  pages(where: {parent_project_id: {_eq: $projectId}}) {
    name
    page_id
    slug
    thumbnails
    order_index
    lookup_entries {
      slug_path
    }
    group {
      name
      slug
      domain
    }
  }
}
`;
