<template>
  <div>
    <b-button
        @click="$bvModal.show('modal-add-item')"
        class="green-button-transparent border-0"
    >
      <b-icon
          icon="plus-circle-fill"
          class="mr-2 top--3"
          aria-hidden="true"
          scale="1"
      ></b-icon>
      {{ $t('buttons.chooseItems') }}
    </b-button>
    <b-modal
        :id="'modal-add-item'"
        :ref="'modal-add-item'"
        centered
        no-close-on-backdrop
        footer-class="flex-nowrap"
        @hidden="resetModal"
    >
      <template #modal-header>
        <h3>{{ $t('modals.chooseItems.title') }}</h3>
      </template>
      <div class="p-5">
        <p>{{ $t('modals.chooseItems.message') }}</p>
        <treeselect
            v-model="treeItems"
            :multiple="true"
            :flat="true"
            :options="allGroupsOfLoggedInUser"
            :loadOptions="loadOptions"
            :clearable="false"
            placeholder="Select an item"
            :noChildrenText="$t('modals.move.noChildren')"
            @select="selectItem"
            @deselect="deselectItem"
        >
          <div slot="value-label" slot-scope="{ node }">{{ node.raw.label }}</div>
        </treeselect>
      </div>
      <template #modal-footer="{ close }">
        <b-button
            class="p-3 border-right-light-grey"
            block
            @click="close()"
        >
          {{ $t('buttons.cancel') }}
        </b-button>
        <b-button
            variant="primary"
            class="p-3"
            block
            :disabled="buttonIsDisabled"
            @click="addItem"
        >
          {{ $t('modals.chooseItems.validate') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import GetAllGroups from '@/graphQlQueries/queries/getAllGroups';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import GetAllFoldersProjectsAndPagesByFolderId from '@/graphQlQueries/queries/getAllFoldersProjectsAndPagesByFolderId';
import GetAllPagesByProjectId from '@/graphQlQueries/queries/getAllPagesByProjectId';

export default {
  name: 'ModalAddNewReportItem',
  props: ['userId', 'itemsFromDB'],
  components: { Treeselect },
  data: () => ({
    selectedItems: [],
    deletedItems: [],
    treeItems: [],
    allGroupsOfLoggedInUser: null,
    idGenerator: 1,
  }),
  apollo: {
    allGroupsOfLoggedInUser: {
      query: GetAllGroups,
      update(data) {
        this.treeItems = [];
        const groupObjects = [];
        data.groups.forEach((groupItem) => {
          const element = {
            id: this.idGenerator,
            itemId: groupItem.group_id,
            label: groupItem.name,
            rootFolderId: groupItem.root_folder_id,
            type: 'group',
            children: null,
          };
          this.idGenerator++;
          if (!groupObjects.find((elem) => elem.itemId === groupItem.group_id)) {
            groupObjects.push(element);
          }
        });
        groupObjects.forEach((groupItem) => {
          this.itemsFromDB.forEach((item) => {
            if (item.type === 'folder' && groupItem.rootFolderId === item.folder_id
              && this.treeItems.find((treeId) => treeId === groupItem.id) === undefined) {
              this.treeItems.push(groupItem.id);
            }
          });
        });
        return groupObjects;
      },
      skip() {
        return !this.userId;
      },
    },
  },
  computed: {
    buttonIsDisabled() {
      return (this.selectedItems.length === 0 && this.deletedItems.length === 0);
    },
  },
  mounted() {
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (modalId === 'modal-add-item') {
        this.$apollo.queries.allGroupsOfLoggedInUser.refresh();
      }
    });
  },
  methods: {
    selectItem(item) {
      const selectedItem = this.deletedItems.find((elem) => elem.id === item.id);
      if (selectedItem) {
        this.deletedItems = this.deletedItems.filter((elem) => elem.itemId !== selectedItem.id);
      } else {
        this.selectedItems.push(item);
      }
    },
    deselectItem(item) {
      const deselectedItem = this.selectedItems.find((elem) => elem.id === item.id);
      if (deselectedItem) {
        this.selectedItems = this.selectedItems.filter((elem) => elem.itemId !== deselectedItem.id);
      } else {
        this.deletedItems.push(item);
      }
    },
    addItem() {
      this.$emit('addItems', this.selectedItems, this.deletedItems);
      const modalRef = 'modal-add-item';
      this.$refs[modalRef].hide();
    },
    resetModal() {
      this.selectedItems = [];
      this.deletedItems = [];
    },
    async loadOptions({ action, parentNode }) {
      if (action === 'LOAD_CHILDREN_OPTIONS') {
        if (parentNode.type === 'group' || parentNode.type === 'folder') {
          // run the query and retrieve all folder and projects of the parent
          const folderId = (parentNode.type === 'group') ? parentNode.rootFolderId : parentNode.itemId;
          // because we don't want to move in the same folder
          const childFolderData = await this.$apollo.query({
            query: GetAllFoldersProjectsAndPagesByFolderId,
            variables: {
              folderId,
            },
            fetchPolicy: 'network-only',
          });
          // eslint-disable-next-line no-param-reassign
          parentNode.children = this.prepareTheDataForChildrenOutput(childFolderData.data);
        } else if (parentNode.type === 'project') {
          const projectId = parentNode.itemId;
          const childProjectData = await this.$apollo.query({
            query: GetAllPagesByProjectId,
            variables: {
              projectId,
            },
            fetchPolicy: 'network-only',
          });
          // eslint-disable-next-line no-param-reassign
          parentNode.children = this.prepareTheDataForChildrenOutput(childProjectData.data);
        }
      }
    },
    prepareTheDataForChildrenOutput(data) {
      const childrenArray = [];
      // we have data.folders
      if (data.folders && data.folders.length > 0) {
        data.folders.forEach((folderItem) => {
          if (this.itemId !== folderItem.folder_id) {
            childrenArray.push({
              id: this.idGenerator,
              itemId: folderItem.folder_id,
              label: folderItem.name,
              name: folderItem.name,
              type: 'folder',
              children: null,
            });
            this.idGenerator++;
          }
        });
      }
      if (data.projects && data.projects.length > 0) {
        data.projects.forEach((projectItem) => {
          if (this.itemId !== projectItem.project_id) {
            childrenArray.push({
              id: this.idGenerator,
              itemId: projectItem.project_id,
              label: projectItem.name,
              type: 'project',
              children: null,
            });
            this.idGenerator++;
          }
        });
      }
      if (data.pages.length > 0) {
        data.pages.forEach((pageItem) => {
          if (this.itemId !== pageItem.page_id) {
            childrenArray.push({
              id: this.idGenerator,
              itemId: pageItem.page_id,
              label: pageItem.name,
              type: 'page',
            });
            this.idGenerator++;
          }
        });
      }
      if (childrenArray.length === 0) {
        return null;
      }
      // now we have to check if the items are preselected
      childrenArray.forEach((childItem) => {
        this.itemsFromDB.forEach((item) => {
          if (childItem.type === item.type) {
            switch (childItem.type) {
              case 'folder':
                if (childItem.itemId === item.folder_id
                  && this.treeItems.find((treeId) => treeId === childItem.id) === undefined) {
                  this.treeItems.push(childItem.id);
                }
                break;
              case 'project':
                if (childItem.itemId === item.project_id
                  && this.treeItems.find((treeId) => treeId === childItem.id) === undefined) {
                  this.treeItems.push(childItem.id);
                }
                break;
              case 'page':
                if (childItem.itemId === item.page_id
                  && this.treeItems.find((treeId) => treeId === childItem.id) === undefined) {
                  this.treeItems.push(childItem.id);
                }
                break;
              default:
                break;
            }
          }
        });
      });
      return childrenArray;
    },
  },
};
</script>

<style scoped>
button.disabled {
  cursor: not-allowed;
}
</style>
